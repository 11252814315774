/* 既存のコンポーネントcssをカバーする */
.fc .fc-toolbar-chunk .fc-button-primary{
    background-color:#1976D2;
}


.fc .fc-toolbar-chunk .fc-button-primary:hover{
    background-color:#1976D2;
}

.fc .fc-toolbar-chunk .fc-button-primary:focus {
    box-shadow: 0 0 0 0.2rem #1976D2;
}

.MuiDialogContent-root .MuiDialogContentText-root {
    margin: 15px 0px;
}

/* 画面最外層のdivスタイル */
.fullcalendar-div-style {
    margin: 50px auto;
    width: 80%;
}

/* ダイアログStyle */
.dialog-title-style {
    background-color: #007BFF;
    width: 280px;
    padding: 15px 24px;
}

.dialog-body-li-name {
    font-weight: 900;
    color: #007BFF;
    font-size: 15px;
}

.dialog-body-li{
    list-style: none;
    margin: 5px 0px 10px -4px;
}

.dialog-body-li-icon {
    margin-right: 9px;
}

.dialog-body-li-time {
    margin-right: 9px;
    font-size: 15px;
}

.dialog-actions-style {
    background-color: #F3F9FD;
}

@media screen and (max-width: 480px) {
    .mobile-width  {
        width: 900px;
    }
  }


.dialog-button {
    font-weight: 900;
    color: #007BFF;
    font-size: 15px;
}
