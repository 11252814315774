.foot-style{
    outline: none ;
    color: #1976D2;
    font-weight: bold
}

.grid-top{
    margin-top: 2%;
}

.table-row-odd-color{
    background-color:#FFFFFF;
}

.table-row-even-color{
    background-color:#cce5ff;
}

.table-row-color-selected{
    background-color: #fff4e5;
    font-weight: bold;
    color: #03305c;
}

.table-row-color-added{
    background-color: #edf7ed;
    color: #1e4620;
}

.table-row-color-deleted{
    background-color: #f6f6f6;
    text-decoration: line-through;
    color: #2f3337;
}

.ant-table-thead > tr >th{
    background-color: #007BFF !important;
    color: white !important;
    font-weight: 900 !important;
}

@media screen and (max-width: 480px) {
    .MuiPaper-root .MuiTable-root  {
        width: 600px;
    }
    .body-init{
        height: 100%;
        margin: 10px auto;
        text-align: left;
    }

    .text-change-size{
        width:200px;
    }

    .textarea-change-size{
        width:200px;
        height:100px;
        color: #4F81BD;
    }

    .MuiGrid-root .foot-margin-bottom{
        margin-bottom: 180px;
    }
}

@media screen and (min-width: 380px) {
    .body-init{
        width: 60%;
        height: 100%;
        margin: 10px auto;
        border:1px solid #C4C4C4;
        padding: 30px auto;
        text-align: left;
    }

    .text-change-size{
        width:400px;
    }

    .textarea-change-size{
        width:480px;
        height:120px;
        color: #4F81BD;
    }

    .textarea-change-size-remark{
        width:480px;
        height:200px;
        color: #4F81BD;
    }

    .MuiGrid-root .foot-margin-bottom{
        margin-bottom: 50px;
    }
}

.userListNodata {
    overflow-y: scroll
}

.userList {
    height: 300.4px;
    overflow-y: scroll
}

.qrReaderDiv {
    width: 300px;
    height: 300px;
    overflow: hidden;
    display: inline-block;
    position: relative;
}

.qrReaderShadow {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 30px solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 100%;
    height: 100%;
}

.qrReader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -250px;
    width: 500px;
    height: 500px;
}

.qrReader video {
    object-fit: cover;
}

.fail-radio-group {
    border: 1px solid #ff0000;
}

.dropdown-select {
    width: 250px;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
}