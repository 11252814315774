body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    --amplify-font-family: ‘Amazon Ember’, ‘Helvetica Neue Light’, ‘Helvetica Neue’, ‘Helvetica’, ‘Arial’, ‘sans-serif’;
    --amplify-text-xxs:	0.75rem;
    --amplify-text-xs:	0.81rem;
    --amplify-text-sm:	0.875rem;
    --amplify-text-md:	1rem;
    --amplify-text-lg:	1.5rem;
    --amplify-text-xl:	2rem;
    --amplify-text-xxl:	2.5rem;
    --amplify-primary-color: #1976D2;
    --amplify-primary-contrast:	var(–amplify-white);
    --amplify-primary-tint:	#4fcafa;
    --amplify-primary-shade:	#0389be;
    --amplify-secondary-color:	#152939;
    --amplify-secondary-contrast:	var(–amplify-white);
    --amplify-secondary-tint:	#31465f;
    --amplify-secondary-shade:	#1F2A37;
    --amplify-tertiary-color:	#5d8aff;
    --amplify-tertiary-contrast:	var(–amplify-white);
    --amplify-tertiary-tint:	#7da1ff;
    --amplify-tertiary-shade:	#537BE5;
    --amplify-grey:	#828282;
    --amplify-light-grey:	#c4c4c4;
    --amplify-white:	#ffffff;
    --amplify-red:	#dd3f5b;
}

amplify-authenticator {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo {
    color: rgb(21, 41, 57);
    font-family: "‘Amazon Ember’", "‘Helvetica Neue Light’", "‘Helvetica Neue’", ‘Helvetica’, ‘Arial’, ‘sans-serif’;
    font-size: 24px;
    font-weight: 700;
    margin-block-start: 5px;
}

.fc-scrollgrid-sync-table tr {
    height: 170px;
}

.fc-event-title {
    text-overflow: ellipsis;
}

.dialog-body-li-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}